import { useState, useRef } from "react";
import Icon from "../../assets/icons";
import { NavLink, useNavigate } from "react-router-dom";
import OurProducts from "../ourProductComponents/ourProducts";
import vendpal from "../../assets/images/Vendpal_Logo.png";
import vendstash from "../../assets/images/Vendstash_Logo.png";

const Navbar = () => {
  const navigate = useNavigate();
  const [buttonText, setButtonText] = useState("Contact Us");
  const [open, setOpen] = useState(false);
  const activeItemRef = useRef("/"); // Use useRef instead of useState

  const handleMobileMenu = (nav: any) => {
    activeItemRef.current = nav; // Update the ref value
    navigate(activeItemRef.current); // Use the ref value
    setOpen(false);
  };
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isMobileModalOpen, setIsMobileModalOpen] = useState(false);

  const showModal = () => {
    setIsModalOpen(true);
  };
  const closeModal = () => {
    setIsModalOpen(false);
  };

  const showMobileModal = () => {
    setIsMobileModalOpen(!isMobileModalOpen);
  };
  return (
    <div className="fixed w-full z-50 top-0 xl:px-16 bg-white">
      <div className="hidden lg:flex items-center md:h-[112px] justify-between ">
        <div className="hidden md:flex items-center">
          <Icon name="logo" />
          <Icon name="logo-text" />
        </div>
        <div className="font-normal text-[20px] leading-[100px] text-[#342F31] flex items-center gap-6 cursor-pointer">
          <span>
            <NavLink
              to="/"
              className={({ isActive }) =>
                isActive
                  ? "no-underline text-primaryPurple px-3 py-1 font-medium"
                  : "no-underline hover:border hover:border-primaryPurple px-3 py-1 hover:rounded-2xl"
              }
            >
              Home
            </NavLink>
          </span>

          <button
            className="no-underline border-none hover:border hover:border-primaryPurple px-3 py-1 hover:rounded-2xl"
            onClick={showModal}
          >
            Our Products
          </button>

          <span>
            <NavLink
              to="/careers"
              className={({ isActive }) =>
                isActive
                  ? "no-underline text-primaryPurple px-3 py-1 font-medium"
                  : "no-underline hover:border hover:border-primaryPurple px-3 py-1 hover:rounded-2xl"
              }
            >
              Careers
            </NavLink>
          </span>
        </div>
        <div>
          <button
            className="text-textPurple text-[20px] bg-primaryPurple rounded-[50px] h-[63px] min-w-[160px] max-w-[161px] hover:font-bold"
            onMouseEnter={() => setButtonText("Hello")}
            onMouseLeave={() => setButtonText("Contact Us")}
          >
          <a href="#contact" className="no-underline">
          {buttonText}
          </a>
          </button>
        </div>
      </div>
      <div className="lg:hidden flex items-center justify-between px-6 py-6">
        <div className="flex items-center">
          <Icon name="phone-logo" />
          <Icon name="phone-logo-text" />
        </div>
        {open ? (
          <button onClick={() => setOpen(false)}>
            <Icon name="hamburger-close" />{" "}
          </button>
        ) : (
          <button onClick={() => setOpen(true)}>
            <Icon name="hamburger-open" />{" "}
          </button>
        )}
      </div>
      {open ? (
        <div className="lg:hidden mt-14 ml-auto h-screen text-xs">
          <div className="flex flex-col gap-6 items-center ">
            <div className="flex items-center justify-center w-full">
              <button
                className="no-underline hover:border hover:border-primaryPurple  py-1 hover:rounded-2xl w-10/12 mx-auto text-center"
                onClick={() => handleMobileMenu("/")}
              >
                Home
              </button>
            </div>

            <button
              className="no-underline text-[#3C3C3C] flex justify-center w-10/12"
              onClick={showMobileModal}
            >
          <span className="flex-1">    Our Products</span>
              <Icon
                name={isMobileModalOpen ? "menu-arrow-up" : "menu-arrow-down"}
                className=""
              />
            </button>
            {isMobileModalOpen && (
              <div className="bg-secondaryPurple px-[13px] py-4">
                <div className=" mb-3 bg-white rounded-[16px] flex justify-between items-center gap-4 p-2">
                  <div className="min-w-[131px]">
                    <img src={vendstash} alt="vendstash" className="w-full" />
                  </div>
                  <div className="border border-[#B1A9AB] h-[127px] w-[1px]" />
                  <div className="flex flex-col gap-4">
                    <p className="uppercase text-[#232628] font-medium text-base leading-[20px]">
                      Transaction made seamless.
                    </p>
                    <p className="text-[#444749] text-xs">
                      We help merchants and buyers stay on top of their
                      transactions. It just works, so you can too.
                    </p>
                  </div>
                </div>

                <div className="  bg-white rounded-[16px] flex justify-between items-center gap-4 p-2">
                  <div className="min-w-[131px]">
                    <img src={vendpal} alt="vendpal" className="w-full" />
                  </div>
                  <div className="border border-[#B1A9AB] h-[127px] w-[1px]" />
                  <div className="flex flex-col gap-4">
                    <p className="uppercase text-[#232628] font-medium text-base leading-[20px]">
                      Your all-in-one procurement platform
                    </p>
                    <p className="text-[#444749] text-xs">
                      We help merchants and buyers stay on top of their
                      transactions. It just works, so you can too.
                    </p>
                  </div>
                </div>
              </div>
            )}

            <div className="flex items-center justify-center w-full">
              <button
                className="no-underline hover:border hover:border-primaryPurple  py-1 hover:rounded-2xl w-10/12 mx-auto text-center"
                onClick={() => handleMobileMenu("/careers")}
              >
                Careers
              </button>
            </div>
            <div className="flex items-center justify-center h-[41px] w-full">
              <button
                className="text-textPurple text-center bg-primaryPurple rounded-[50px] w-10/12 h-full hover:font-bold"
                onMouseEnter={() => setButtonText("Hello")}
                onMouseLeave={() => setButtonText("Contact Us")}
                onClick={() => handleMobileMenu("/#contact")}
              >
                <a href='#contact'>
                {buttonText}
                </a>
              </button>
            </div>
          </div>
        </div>
      ) : null}
      <OurProducts open={isModalOpen} closeModal={closeModal} />
    </div>
  );
};

export default Navbar;
