import React, {useEffect} from 'react'
import SingleJobDescription from '../components/careerComponents/singleJob'
import { Link } from 'react-router-dom'

const JobDescription = () => {
  useEffect(()=>{
    window.scrollTo(0,0);
  },[])
  return (
    <div className='min-h-[80vh]  mt-20 lg:mt-[112px]'>
      <div className='bg-secondaryPurple py-6 pl-4 lg:pt-14 lg:pb-16 lg:pl-16'>
        <div className='flex items-center gap-2 text-[12px] leading-[24px] font-light lg:text-[20px]'>
          <span className='text-[#4A4547]'>
            <Link to="/careers" className='no-underline'>Career</Link>
          </span>
          <span> {">"}</span>
          <span className='text-[#000000]'>Job Information</span>
        </div>
      </div>
        <SingleJobDescription/>
    </div>
  )
}

export default JobDescription