import React, {useEffect} from 'react'
import CareerHero from '../components/careerComponents/careerHero'
import Creative from '../components/careerComponents/creative'
import JobOpenings from '../components/careerComponents/jobOpenings'
import Faqs from '../components/careerComponents/faqs'

const Career = () => {
  useEffect(()=>{
    window.scrollTo(0,0);
  },[])
  return (
    <div className='mt-20 lg:mt-[112px]'>

      <CareerHero/>
      <Creative/>
      <JobOpenings/>
      <Faqs/>
    </div>
  )
}

export default Career