import React, { useState } from 'react';
import Icon from '../../assets/icons';

const Faqs = () => {
  const [activeIndex, setActiveIndex] = useState(null);

  const toggleFAQ = (index:any) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  const faqs = [
    {
      question: 'Many companies are pushing employees back to the office. Can I really work from home?',
      answer: "Mitochron believes in offering flexibility and the vast majority of our teammates work from home! We're fully committed to being a remote-first workplace.If you don't think remote work is the best choice for you, we also have offices available in many global locations. And like most companies, we have a limited number of positions that must be worked from an office."
    },
    {
      question: 'How do I track my order?',
      answer: "Mitochron believes in offering flexibility and the vast majority of our teammates work from home! We're fully committed to being a remote-first workplace.If you don't think remote work is the best choice for you, we also have offices available in many global locations. And like most companies, we have a limited number of positions that must be worked from an office.",
    },
    {
      question: 'Many companies are pushing employees back to the office. Can I really work from home?',
      answer: "Mitochron believes in offering flexibility and the vast majority of our teammates work from home! We're fully committed to being a remote-first workplace.If you don't think remote work is the best choice for you, we also have offices available in many global locations. And like most companies, we have a limited number of positions that must be worked from an office.",
    },
  ];

  return (
    <div className="mx-auto py-[88px] lg:py-[152px] lg:px-16">
      <h2 className=" mb-12 uppercase text-center text-primaryPurple !font-headingBold text-[24px] leading-[28px] md:text-[32px] md:leading-[40px] lg:text-[64px] lg:leading-[74px] font-bold md:mb-12">frequently asked questions</h2>
      {faqs.map((faq, index) => (
        <div key={index} className="mb-4">
          <button
            className={`w-full  flex  items-center justify-between gap-10 text-left text-[#4A4547] font-light  border-b border-b-[#D3C2C9] p-4 text-xs md:text-sm lg:text-[28px] lg:leading-[40px] ${index === 0 ? 'border-t border-t-[#D3C2C9]' : ''}`}
            onClick={() => toggleFAQ(index)}
          >
            
            {faq.question}
            {activeIndex === index ? (
              <span className="mr-2">🔼</span> // Icon for open state
            ) : (
             <div className='min-w-12 min-h-12 lg:w-[76px] lg:h-[76px]'>
               <Icon name='faq-arrow-close' className='h-full w-full'/> 
             </div>// Icon for close state
            )}
          </button>
          <div className={` p-4 font-light text-[#342F31] text-xs md:text-sm lg:text-2xl bg-secondaryPurple transition-all duration-300 ${activeIndex === index ? 'block' : 'hidden'}`}>
            <p>{faq.answer}</p>
          </div>
        </div>
      ))}
    </div>
  );
};

export default Faqs;
