import React from "react";
import Icon from "../../assets/icons";

const Footer = () => {
  return (
    <div>
      <div className="bg-primaryPurple pt-12 md:pt-[128px] flex flex-col items-center justify-center gap-8 md:gap-16 ">
       <div className="w-11/12 h-[32.62px]  lg:h-[130.5px]">
       <Icon name="desktop-footer-logo" width='full' height='full' />
       </div>
        <p className="text-white font-normal text-xs md:text-base lg:text-2xl text-center px-4 pb-8">
          {" "}
          A private limited company incorporated in Nigeria under company number
          xxxxxxxxx. Registered at <br className="hidden md:block" /> Third
          Floor, xxxxxxxxxxxxxxxxxxxxxxx.
        </p>
      </div>
      <div className="bg-secondaryPurple py-8 md:h-[206px] flex items-center justify-center">
        <div className="flex flex-col-reverse gap-4 md:flex-row justify-between w-full md:px-16">
          <div className="text-[#292526] text-xs lg:text-[24px] lg:leading-[32px] font-medium text-center">© 2024 Mitochron</div>
          <div className="flex gap-[72px] justify-center items-center text-[#292526] text-xs lg:text-[24px] lg:leading-[32px] font-normal">
            <span>Terms of Service</span>
            <span>Privacy Policy</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
