import React from "react";

const Contact = () => {
  return (
    <div className="bg-white py-[88px] md:py-[152px] md:px-[64px]" id="contact">
      <div className="hidden lg:block text-left text-primaryPurple text-[28px] leading-[32px] font-headingBold md:text-[64px] font-bold md:leading-[90px] mb-12 px-5">
      Need to speak with an expert?
    </div>
    <div className="text-left text-primaryPurple text-[28px] leading-[32px] font-headingBold md:text-[64px] font-bold md:leading-[90px] lg:hidden mb-12 px-5">
    Need to speak with an expert?
    </div>
      <div className="w-full mt-8 lg:mt-16 flex items-center justify-start px-5">
        <button className="h-[41px] text-sm lg:text-[24px] w-7/12 md:h-[63px] lg:w-[389px] lg:h-[82px] text-textPurple text-[24px] bg-primaryPurple rounded-[50px] hover:font-bold">
          <a href="mailto:mitochronhub@gmail.com">GET IN TOUCH</a>
        </button>
      </div>

      {/**
       *  <div className="hidden lg:block text-center text-primaryPurple text-[28px] leading-[32px] font-headingBold md:text-[64px] font-bold md:leading-[90px] mb-12 px-5">
      GET IN TOUCH
    </div>
    <div className="text-center text-primaryPurple text-[28px] leading-[32px] font-headingBold md:text-[64px] font-bold md:leading-[90px] lg:hidden mb-12 px-5">
      JOIN THE CREATIVE NETWORK
    </div>
    <div className='px-4 lg:px-[88px]'> 
       <div>
        <label className='text-primaryPurple text-[12px] lg:text-[20px] leading-[72px] lg:leading-[100px]'>ENTER YOUR FULL NAME</label>
        <div className='bg-[#EDDFE4] h-[51px] rounded-[48px] lg:h-[96px] p-4'>
          <input className='bg-inherit h-full w-full outline-none border-none'/>
        </div>
      </div>
      <div>
        <label className='text-primaryPurple text-[12px] lg:text-[20px] leading-[72px] lg:leading-[100px]'>ENTER YOUR EMAIL</label>
        <div className='bg-[#EDDFE4] h-[51px] rounded-[48px] lg:h-[96px] p-4'>
          <input className='bg-inherit h-full w-full outline-none border-none'/>
        </div>
      </div>
      <div>
        <label className='text-primaryPurple text-[12px] lg:text-[20px] leading-[72px] lg:leading-[100px]'>ENTER YOUR MESSAGE</label>
        <div className='bg-[#EDDFE4] h-[148px] rounded-[24px] lg:rounded-[64px] lg:h-[204px] p-4'>
          <textarea className='bg-inherit h-full w-full outline-none border-none'/>
        </div>
      </div>
      </div>
       */}
    </div>
  );
};

export default Contact;
