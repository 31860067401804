import React, {useEffect} from 'react'
import AppRoutes from './routes'
import '../src/styles/font.css'

const App = () => {
  useEffect(()=>{
    window.scrollTo(0,0);
  },[])
  return (
    <>
    <AppRoutes/>
    </>
  )
}

export default App