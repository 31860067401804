import { useRoutes } from "react-router-dom";
import Home from "../pages/homePage";
import Career from "../pages/careerPage";
import Layout from "../layout";
import JobDescription from "../pages/jobDescription";

export default function AppRoutes() {
  return useRoutes([
    {
      path: "/",
      element: <Layout />,
      children: [
        { path: "/", element: <Home /> },
        { path: "/careers", element: <Career /> },
        {path: `/description/:id`, element: <JobDescription/>}
      ],
    },
  ]);
}
