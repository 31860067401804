import React from 'react'
import Icon from '../../assets/icons'

const Creative = () => {
    const offerInfo = [
        {title: "This is the moment.", iconName:"creative-one", text: "It’s an exciting time at Mitochron, with new leadership, a refreshed brand, and a whole new approach to changing the way the world works. We’re at the forefront of a movement, and we want you to ride this wave with us."}, 
        {title: "We want you to be you.", iconName:"creative-two",text: "Too often, companies tell you about their culture and then expect you to fit it. Our culture is built from the people who work here. We want you to feel safe to be who you are, take risks, and show us what you’ve got."},
        {title: "It’s your world.",iconName:"creative-three", text: "At Mitochron, we’re serious about empowering people to work when, how, and where they want. Couch? Sweatpants? We believe that happy employees mean happy customers. That’s why we hire amazing people and get out of their way."}
    ]
  return (
    <div className="bg-white py-[88px] px-[21px] md:py-[152px] md:px-[95px]">
    <div className=" text-center text-primaryPurple md:mb-[96px] ">
      <p className="uppercase !font-headingBold text-[24px] leading-[28px] md:text-[32px] md:leading-[40px] lg:text-[64px] lg:leading-[74px] font-bold md:mb-12">
      The top creative and technical minds can work anywhere. So why mitochron hub?
      </p>
    </div>
    <div className='flex justify-center'>
    <div className="grid grid-cols-1 lg:grid-cols-3 gap-4 lg:gap-8  py-10">
      {offerInfo.map((info:any) => {
        return (
          <div
            className="bg-secondaryPurple rounded-[32px] lg:rounded-[56px] px-6 pt-6 pb-7 lg:px-8 lg:pt-8 lg:pb-12"
          >
            
            <div className="flex flex-col items-start md:gap-[32px] ">
                <div className='w-20 h-20 lg:w-[98px] lg:h-[98px]'>
                <Icon name={info.iconName} />
                </div>
              <span className="text-left text-base lg:text-[24px] lg:leading-[36px] font-medium">
                {info.title}
              </span>
              <span className="text-left text-xs md:text-sm lg:text-[20px] lg:leading-[28px] font-light">
                {info.text}
              </span>
            </div>
          </div>
        );
      })}
    </div>
    </div>
  </div>
  )
}

export default Creative