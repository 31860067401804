import React from "react";

import "pure-react-carousel/dist/react-carousel.es.css";
import morife from "../../assets/images/Morife_IMG.png";
import segun from "../../assets/images/Segun_IMG.png";
import banji from "../../assets/images/Banji_IMG.png";

/* Install pure-react-carousel using -> npm i pure-react-carousel */

export default function Mitochrons() {
  return (
    <div className="bg-secondaryPurple py-[88px] md:py-[152px] md:px-[64px]">
      <div className="text-center text-primaryPurple text-[28px] leading-[32px] font-headingBold md:text-[64px] font-bold md:leading-[90px] mb-12 px-5">
        MEET THE MITOCHRONS
      </div>
      <div className=" flex items-center justify-center w-full h-full text-[#FFFFFF]">
        {/* Carousel for desktop and large size devices */}

        <div className="w-full relative flex items-center justify-center">
          <div className="w-full h-full mx-auto">
            <div id="slider" className="h-full flex overflow-scroll 2xl:justify-center">
              <div>
                <div className="w-[300px] lg:w-[416px] mx-2 overflow-hidden relative"> {/* Added relative positioning */}
                  <img
                    src={morife}
                    alt="morife"
                    className="object-contain w-full h-full"
                  />
                  <div className="absolute inset-0 flex flex-col items-center justify-end gap-6 text-white p-2 pb-8 lg:pb-12"> {/* Centered text overlay */}
                  <span className="text-white uppercase text-center font-bold text-[24px] leading-[28px] lg:text-[45px] lg:leading-[50px]">Morifeoluwa Jebutu</span>
                  <span className="text-white font-normal text-[20px] leading-[24px] text-center lg:text-[36px] ">FOUNDER</span>
                  </div>
                </div>
              </div>

              <div>
              <div className="w-[300px] lg:w-[416px] mx-2 overflow-hidden relative"> {/* Added relative positioning */}
                  <img
                    src={segun}
                    alt="segun"
                    className="object-contain w-full h-full"
                  />
                  <div className="absolute inset-0 flex flex-col items-center justify-end gap-6 text-white p-2 pb-8 lg:pb-12"> {/* Centered text overlay */}
                  <span className="text-white uppercase text-center font-bold text-[24px] leading-[28px] lg:text-[45px] lg:leading-[50px]">Olusegun Oyesanya</span>
                  <span className="text-white font-normal text-[20px] leading-[24px] text-center lg:text-[36px] ">CO-FOUNDER</span>
                  </div>
                </div>
              </div>

              <div>
              <div className="w-[300px] lg:w-[416px] mx-2 overflow-hidden relative"> {/* Added relative positioning */}
                  <img
                    src={banji}
                    alt="banji"
                    className="object-contain w-full h-full"
                  />
                  <div className="absolute inset-0 flex flex-col items-center justify-end gap-6 text-white p-2 pb-8 lg:pb-12"> {/* Centered text overlay */}
                  <span className="text-white uppercase text-center font-bold text-[24px] leading-[28px] lg:text-[45px] lg:leading-[50px]">Banji <br className="hidden lg:block"/> Ogunyemi</span>
                  <span className="text-white font-normal text-[20px] leading-[24px] text-center lg:text-[36px] ">FOUNDER</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Carousel for tablet and medium size devices 
        <CarouselProvider
          cl*/}
      </div>
    </div>
  );
}
