import React from "react";
import Icon from "../../assets/icons";

const Testimonials = () => {
  const iconNames = [
    { name: "testimonial-one" },
    { name: "testimonial-two" },
   
  ];
  return (
    <div className="bg-primaryPurple py-8 px-4 xl:px-[186px] md:py-[72px]">
      <div className="flex flex-col items-center">
        <p className="text-secondaryTextPurple text-sm md:text-[20px] lg:leading-[100px] text-center mb-6">
        Trusted to build next-gen solutions
        </p>
        <div className="flex items-center  max-w-full justify-between h-7 md:h-16  md:gap-[88px]">
          {iconNames.map((iconName: any) => {
            return <Icon name={iconName.name} height="full" />;
          })}
        </div>
      </div>
    </div>
  );
};

const WhoWeAre = () => {
  const iconNames = [
    {
      name: "whoWeAre-one",
      description: "Technology products and services for businesses",
    },
    {
      name: "whoWeAre-two",
      description: "Customized solutions through efficient technology",
    },
    {
      name: "whoWeAre-three",
      description: "Full-scale development and maintenance",
    },
    {
      name: "whoWeAre-four",
      description: "Tailored solutions that meet clients’ and customers’ needs",
    },
  ];
  return (
    <div className="py-[100px] md:py-[152px] md:px-[80px] bg-secondaryPurple">
      <div className="flex flex-col items-center">
        <p className="!font-headingBold uppercase text-[24px] leading-[28px] md:text-[32px] md:leading-[40px] lg:text-[64px] font-bold text-primaryPurple lg:leading-[90px] text-center mb-12">
          What does Mitochron <br className="block md:hidden" /> Hub provide
        </p>
        <div className=" grid grid-cols-2 gap-12 lg:grid-cols-4 lg:gap-[72px] lg:h-[249px] px-[28px]">
          {iconNames.map((iconName: any) => {
            return (
              <div
                className="col-span-1 flex flex-col md:gap-12 items-center h-full"
                key={iconName.name}
              >
                <div className="h-14 md:h-[130px] w-[48.16px] max-w-[48.16px] md:w-[226.02px] md:max-w-[226.02px]">
                  {" "}
                  <Icon name={iconName.name} height="full" width="full" />
                </div>
                <span className=" text-[12px] leading-[16px] md:text-base lg:text-[20px] lg:leading-[28px] text-primaryPurple text-center">
                  {iconName.description}
                </span>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

const Offer = () => {
  const offerInfo = [
    {
      title: "WHO WE ARE",
      text: "We’re a technology company that specializes in developing solutions for business and individuals.",
    },
    {
      title: "WHAT WE DO",
      text: "We develop and provide products and services, software solutions, consulting, and design services for businesses across the world.",
    },
    {
      title: "WHERE WE ARE",
      text: "We’re focused on helping small and medium-sized businesses scale before they’re launched, so they can ‘gamify’ and grow an audience from the get-go.",
    },
    {
      title: "WHERE WE ARE GOING",
      text: "The future will include investment in startups and business ideas with a hands-on involvement in their development process.",
    },
  ];
  return (
    <div className="bg-white">
      <div className="mt-[88px] px-[21px] md:mt-[152px] md:mb-[96px] md:px-[95px] text-center text-primaryPurple">
        <p className="!font-headingBold text-[24px] leading-[28px] md:text-[32px] md:leading-[40px] lg:text-[64px] lg:leading-[74px] font-bold mb-6 md:mb-12">
          We offer innovative tech products <br className="hidden md:block" />{" "}
          and services.
        </p>
        <p className="text-xs md:text-base lg:text-[22px] lg:leading-[32px] font-light">
          Mitochron Hub supports entrepreneurs and teams in the process of
          building fast, scalable <br className="hidden md:block" /> businesses,
          by providing the right services, guidance and support at each step of
          their journey.
        </p>
      </div>
      <div className="">
      <div className="grid grid-cols-1 lg:grid-cols-4 md:py-10">
        {offerInfo.map((info, index) => {
          return (
            <div
              className={`col-span-1 lg:max-w-[360px] py-[40.5px] px-[42px] text-primaryPurple ${
                index < offerInfo.length - 1
                  ? "border-b border-b-primaryPurple lg:border-r lg:border-r-primaryPurple50 lg:border-b-0"
                  : ""
              }`}
            >
              <div className="flex flex-col items-center md:gap-[32px] ">
                <span className="text-center text-[20px] md:text-[24px] lg:text-[32px] md:leading-[28px] font-bold">
                  {info.title}
                </span>
                <span className="text-center text-xs md:text-base lg:text-[20px] md:leading-[28px] font-light">
                  {info.text}
                </span>
              </div>
            </div>
          );
        })}
      </div>
      </div>
    </div>
  );
};



const About = () => {
  return (
    <div>
     <Testimonials />
      <WhoWeAre />
      <Offer />
    </div>
  );
};

export default About;
