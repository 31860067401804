import React, { useState } from "react"; // Add useState import
import hero from "../../assets/images/CareerHero.png";

const CareerHero = () => {
  const [buttonText, setButtonText] = useState("See open opportunities"); // State for button text

  return (
    <div className="flex flex-col-reverse md:flex-row h-[calc(100vh-14px)] lg:h-[calc(100vh-112px)] mt-[14px] lg:mt-[112px]">
      <div className="bg-secondaryPurple md:basis-1/2 flex flex-col items-center justify-center">
        <div className="py-16 px-2.5" >
          <p className="mb-8 md:mb-12 uppercase font-headingBold text-primaryPurple text-4xl md:text-5xl xl:text-[88px] lg:leading-[90px] font-extrabold text-center ">Come join us</p>

          <p className="text-textCareerPurple text-center xl:text-left text-base lg:text-[28px] lg:leading-[40px]">
            {" "}
            We’re shaping the future. Discover how you{" "}
            <br className="hidden md:block" /> can join us.
          </p>
          <div className="w-full mt-8 lg:mt-16 flex items-center justify-center xl:justify-start">
            <button 
              className="h-[41px] text-sm lg:text-[24px] w-10/12 md:h-[63px] lg:w-[389px] lg:h-[82px] text-textPurple text-[24px] bg-primaryPurple rounded-[50px] hover:font-bold"
              onMouseEnter={() => setButtonText("We wish you goodluck")} 
              onMouseLeave={() => setButtonText("See open opportunities")} 
            >
               <a href = '#opportunities'>
               {buttonText} 
               </a>
            </button>
          </div>
          
        </div>
       
      </div>
      <div className="md:basis-1/2">
        <img src={hero} alt="hero" className="object-cover w-full h-full" />
      </div>
    </div>
  );
};

export default CareerHero;
