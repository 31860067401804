import React from 'react'
import hero from '../../assets/images/MainHero.png'
import phoneHero from '../../assets/images/PhoneScreen.png'

const Hero = () => {
  return (
    <div className="relative h-[656px]">
      <img 
        src={phoneHero} 
        alt="Hero" 
        className="absolute inset-0 object-cover w-full h-full md:hidden" 
        
      />
      <img 
        src={hero} 
        alt="Hero" 
        className="hidden md:block absolute inset-0 object-cover w-full h-full" 
        
      />
      <div className="absolute inset-0 flex flex-col items-center justify-center text-white px-3 ">
        <h1 className="uppercase text-4xl md:text-5xl lg:text-[88px] lg:leading-[90px] font-extrabold text-center">Technology solutions to <br className='hidden md:block'/> help your business grow</h1>
        <p className='md:text-[28px] md:leading-[40px] font-normal text-center'>We help businesses and individuals deliver a better customer experience <br className='hidden md:block'/> and protect their brand reputation.</p>
      </div>
    </div>
  );
}

export default Hero