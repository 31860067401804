import { Link } from "react-router-dom";

const SingleJobDescription = () => {
  const benefits = [
    { name: "Competitive Pay" },
    { name: "Paid Vacation and Holidays" },
    { name: "Performance Bonus" },
    { name: "Ongoing Training Opportunities" },
  ];
  const qualifications = [
    { name: "Ability to sit for long periods." },
    { name: "Ability to view a computer screen for long periods." },
    { name: "Ability to work under pressure to output high volume, high-quality work." },
    { name: "Be proficient in Adobe Illustrator." },
  ];

  const qualities = [
    {name: "Be able to prioritize what needs to be done and work autonomously."},
    {name: "Work on multiple projects simultaneously."},
    {name: "Be accountable for the expected workload."},
    {name: "Determine the best output method based on the customer’s need."},
    {name: "Read, interpret, and allocate appropriate time to a Work Order."},
    {name: "Work with co-workers and customers with file transfers, FTP access, photo library options, online proofs, etc."},
    {name: "Determine size and arrangement of illustrative material and copy, select style and size of type, and arrange layout based upon available space, aesthetic design, and production requirements."},
    {name: "Inspect jobs for accuracy before sending them to output. Ensure correct material is used for each job and output device settings are accurate."},
    {name: "Prepare comprehensive illustrations, sketches, layouts, and copy for print reproduction according to instructions of the client or supervisor."},
  ]
  return (
    <div className="px-4 py-12 lg:py-24 lg:px-16">
      <section className="mb-12 lg:mb-24">
        <p className="text-[16px] leading-[36px] font-medium text-[#232628] mb-6 lg:text-[24px]">
          JOB DESCRIPTION
        </p>
        <p className="text-[12px] leading-[20px]  font-light text-[#4A4547] lg:text-[20px] lg:leading-[28px]">
          This Graphic Designer position is responsible for creating
          computer-generated full-color graphics and/or vinyl output that can be
          weeded, cut and applied or printed and mounted to a substrate. This
          may involve various levels of artistic creativity and will ultimately
          lead to the complete or modified design from customer drawings, files,
          or exact output of customer-provided design for output to media.
          Scheduling for this position is flexible around the needs of the
          employee and the company.
        </p>
      </section>
      <section className="mb-12 lg:mb-24">
        <p className="text-[16px] leading-[36px] font-medium text-[#232628] mb-6 lg:text-[24px]">
          BENEFITS/PERKS
        </p>
        <ul>
            {
                benefits.map((benefit:any)=>{
                    return(
                        <li className="flex items-center mb-4 text-[12px] leading-[20px]  font-light text-[#4A4547] lg:text-[20px] lg:leading-[28px]   lg:mb-6"> <div className="min-w-[7px] h-[7px] bg-[#EA1345] rounded-full mr-3  md:min-w-2.5 md:h-2.5 md:mr-6"/> {benefit.name}</li>
                    )
                })
            }
        </ul>
      </section>
      <section className="mb-12 lg:mb-24">
        <p className="text-[16px] leading-[36px] font-medium text-[#232628] mb-6 lg:text-[24px]">
          QUALIFICATIONS
        </p>
        <ul>
            {
                qualifications.map((qualification:any)=>{
                    return(
                        <li className="flex items-center mb-4 text-[12px] leading-[20px]  font-light text-[#4A4547] lg:text-[20px] lg:leading-[28px]   lg:mb-6"> <div className="min-w-[7px]  h-[7px] bg-[#EA1345] rounded-full mr-3  md:min-w-2.5 md:h-2.5 md:mr-6"/> {qualification.name}</li>
                    )
                })
            }
        </ul>
      </section>
      <section className="mb-12 lg:mb-24">
        <p className="text-[16px] leading-[36px] font-medium text-[#232628] mb-6 lg:text-[24px]">
          QUALITY
        </p>
        <ul>
            {
                qualities.map((quality:any)=>{
                    return(
                        <li className="flex items-center mb-4 text-[12px] leading-[20px]  font-light text-[#4A4547] lg:text-[20px] lg:leading-[28px]  lg:mb-6"> <div className="min-w-[7px] w-[7px] h-[7px] bg-[#EA1345] rounded-full mr-3  md:min-w-2.5 md:h-2.5 md:mr-6"/> {quality.name}</li>
                    )
                })
            }
        </ul>
      </section>
      <div className="flex justify-center items-center gap-4 lg:gap-6">
        <button className="w-[123px] h-[41px] lg:w-[193px] rounded-[50px] border border-[#79747E] lg:h-[63px] flex items-center justify-center text-[#4A4547] text-[12px] lg:text-[20px]">
          <Link to ="/careers">
          Cancel</Link>
        </button>

        <button className="w-[123px] h-[41px] lg:w-[193px] rounded-[50px] bg-primaryPurple lg:h-[63px] flex items-center justify-center text-textPurple text-[12px] lg:text-[20px]">Apply</button>
      </div>
    </div>
  );
};

export default SingleJobDescription;
