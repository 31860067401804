import React from "react";
import { Modal } from "antd";
import vendpal from "../../assets/images/Vendpal_Logo.png";
import vendstash from "../../assets/images/Vendstash_Logo.png"

const OurProducts = ({ open, closeModal }: any) => {
  return (
    <>
      <Modal
        open={open}
        onCancel={closeModal}
        closable={false}
        footer={null}
        maskClosable
        width={871.23}
      >
        <div className=" mb-1.5 lg:w-[823.23px] lg:rounded-[16px] bg-secondaryPurple flex justify-between items-center gap-6 p-4">
          <div>
            <img src={vendstash} alt="vendstash" />
          </div>
          <div className="border border-[#B1A9AB] h-[128px] w-[1px]" />
          <div className="flex flex-col gap-6">
            <p className="uppercase text-[#232628] font-medium text-[24px] leading-[36px]">
            Transaction made seamless.
            </p>
            <p className="text-[#444749] text-[20px] leading-[28px]">
            We help merchants and buyers stay on top of their transactions.
It just works, so you can too.
            </p>
          </div>
        </div>

        <div className="lg:w-[823.23px] lg:rounded-[16px] bg-secondaryPurple flex justify-between items-center gap-6 p-4">
          <div>
            <img src={vendpal} alt="vendpal" />
          </div>
          <div className="border border-[#B1A9AB] h-[128px] w-[1px]" />
          <div className="flex flex-col gap-6">
            <p className="uppercase text-[#232628] font-medium text-[24px] leading-[36px]">
              Your all-in-one procurement platform
            </p>
            <p className="text-[#444749] text-[20px] leading-[28px]">
              We help merchants and buyers stay on top of their transactions. It
              just works, so you can too.
            </p>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default OurProducts;
