import React from 'react'
import Hero from '../components/homeComponents/hero'
import About from '../components/homeComponents/about'
import Mitochrons from '../components/homeComponents/mitochrons'
import Contact from '../components/homeComponents/contact'

const Home = () => {
  return (
    <div className='mt-20 lg:mt-[112px]'>
      <Hero/>
      <About/>
      <Mitochrons/>
      <Contact/>
    </div>
  )
}

export default Home